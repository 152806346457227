import classNames from 'classnames';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import blockConductor from '../blocks/block-conductor';
import ContentBody from '../components/content-body';
import ContentFooter from '../components/content-footer';
import Header from '../components/header';
import Image from '../components/image';
import Layout from '../components/layout';
import Markdown from '../components/markdown';
import RelatedReadingTile from '../components/related-reading-tile';
import SEO from '../components/SEO';
import SectionOpener from '../sections/section-opener/section-opener';
import SectionRelatedReading from '../sections/section-related-reading';

class SingleInsightPage extends Component {
  render() {
    const { data, location } = this.props;
    const { contentfulMagazine: page } = data;

    const insightStructuredData = [
      {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        'mainEntityOfPage': {
          '@type': 'WebPage',
          '@id': location.href,
        },
        'headline': page.title ? page.title : '',
        'image': [page.main_image && page.main_image.file ? page.main_image.file.url : ''],
        'datePublished': page.unformattedPublishDate,
        'dateModified': page.unformattedPublishDate,
        'author': {
          '@type': 'Person',
          'name': page.authors ? page.authors[0].name : '',
        },
        'publisher': {
          '@type': 'Organization',
          'name': 'Edenspiekermann',
          'logo': {
            '@type': 'ImageObject',
            'url': 'https://google.com/logo.jpg',
          },
        },
        'description': page.teaser ? page.teaser.teaser : '',
      },
    ];

    let renderedBlocks = null;
    if (page.body_blocks) {
      renderedBlocks = page.body_blocks.map((block, i) => blockConductor(block, i));
    }

    const pageWrapClasses = classNames({
      'page': true,
      'page--insight': true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          <SEO
            title={page.title}
            description={page.teaser?.teaser}
            shareImage={getSrc(page.main_image)}
            structuredData={insightStructuredData}
            metadata={page.metadata}
          />
          <SectionOpener openerType="insight">
            <Header
              heading={page.title ? page.title : ''}
              label={page.post_category ? page.post_category : ''}
            />
            {page.introduction && (
              <Markdown
                className="insight__introduction"
                markdown={page.introduction.childMarkdownRemark.html}
              />
            )}
            {page.main_image && <Image image={page.main_image} alt={page.main_image.title} />}
          </SectionOpener>

          <div className="insight__float-wrap">
            {page.nextRead && (
              <div className="insight__next-read">
                <p className="label--no-line">Next Read</p>
                <RelatedReadingTile reading={page.nextRead[0]} />
              </div>
            )}

            {page.body && (
              <ContentBody
                authors={page.authors}
                date={page.published_date}
                body={page.body.childMarkdownRemark.html}
              />
            )}

            {renderedBlocks && <>{renderedBlocks}</>}
          </div>

          {page.contact_persons && (
            <ContentFooter contacts={page.contact_persons ? page.contact_persons : null} />
          )}

          {page.relatedReading && (
            <SectionRelatedReading label="Related Reading" reading={page.relatedReading} darkMode />
          )}
        </Layout>
      </div>
    );
  }
}

export default SingleInsightPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulMagazine(slug: { eq: $slug }) {
      title
      slug
      teaser {
        teaser
      }
      unformattedPublishDate: published_date
      published_date(formatString: "MMMM Do, YYYY")
      post_category
      introduction {
        childMarkdownRemark {
          html
        }
      }
      main_image {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: FULL_WIDTH)
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      ...InsightsBlocksFragment
      contact_persons {
        name
        shortName
        active_employee
        avatar {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 200, aspectRatio: 1)
        }
        position
        slug
        email
        office {
          city
        }
      }
      nextRead {
        ... on Node {
          ... on ContentfulMagazine {
            __typename
            title
            slug
            post_category
            teaser {
              childMarkdownRemark {
                html
              }
            }
            main_image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 400)
            }
          }
        }
        ... on Node {
          ... on ContentfulProject {
            __typename
            title
            slug
            subtitle
            main_image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 500)
            }
          }
        }
      }
      relatedReading {
        ... on Node {
          ... on ContentfulMagazine {
            __typename
            title
            slug
            post_category
            teaser {
              childMarkdownRemark {
                html
              }
            }
            main_image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 400)
            }
          }
        }
        ... on Node {
          ... on ContentfulProject {
            __typename
            title
            slug
            subtitle
            main_image {
              title
              file {
                contentType
              }
              gatsbyImageData(layout: CONSTRAINED, width: 500)
            }
          }
        }
      }
      authors {
        name
        slug
        active_employee
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

SingleInsightPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
