import React from 'react';

import { resolve } from '../urls';

import Link from './link';
import Markdown from './markdown';

const ContentBody = (props) => {
  const { authors, date, body } = props;

  return (
    <div className="content-body">
      <div className="content-body__meta">
        <div className="content-meta">
          {date && (
            <time itemProp="datePublished" className="content-meta__date">
              {date}
            </time>
          )}
          {authors && (
            <div className="content-meta__author" itemProp="author">
              By{' '}
              {authors.map((author, i) => {
                if (author.active_employee) {
                  if (i + 1 === authors.length) {
                    return (
                      <Link
                        className="author__link"
                        to={resolve('people:detail', {
                          peopleSlug: author.slug,
                        })}
                        itemProp="name"
                        key={i}
                      >
                        {author.name}
                      </Link>
                    );
                  }
                  return (
                    <React.Fragment key={i}>
                      <Link
                        className="author__link"
                        to={resolve('people:detail', {
                          peopleSlug: author.slug,
                        })}
                        itemProp="name"
                        key={i}
                      >
                        {author.name}
                      </Link>
                      <span> and </span>
                    </React.Fragment>
                  );
                }
                if (i + 1 === authors.length) {
                  return <span>{author.name}</span>;
                }
                return <span>{author.name} and </span>;
              })}
            </div>
          )}
        </div>
      </div>
      <div className="content-body__content" itemProp="articleBody">
        {body && <Markdown markdown={body} isChapter />}
      </div>
    </div>
  );
};

export default ContentBody;
